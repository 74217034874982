var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-card',{staticClass:"account-transaction-list-dt",attrs:{"off":_vm.windowMode}},[(!_vm.dAccountByAddressError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.dHasNext,"mobile-view":_vm.cMobileView,"loading":_vm.cLoading,"first-m-v-column-width":"5","infinite-scroll":"","fixed-header":"","f-card-off":"","caption":("Transaction list - " + (_vm.formatHexToInt(_vm.totalCount)) + " items")},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-status",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-7"},[_c('f-transaction-status',{staticClass:"light",attrs:{"status":value}})],1)]):[_c('f-transaction-status',{staticClass:"light",attrs:{"status":value}})]]}},{key:"column-timestamp",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-7"},[_c('a',{staticClass:"break-word",attrs:{"href":("" + _vm.explorerUrl + _vm.explorerTransactionPath + "/" + (item.transaction.hash)),"target":"_blank","rel":"noopener"}},[_vm._v(" "+_vm._s(value)+" ")])])]):[_c('a',{staticClass:"break-word",attrs:{"href":("" + _vm.explorerUrl + _vm.explorerTransactionPath + "/" + (item.transaction.hash)),"target":"_blank","rel":"noopener"}},[_vm._v(" "+_vm._s(value)+" ")])]]}},{key:"column-address",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-7"},[_c('a',{attrs:{"href":(_vm.explorerUrl + "address/" + value),"rel":"noopener","target":"_blank"}},[_c('f-ellipsis',{attrs:{"text":value || '',"overflow":"middle"}})],1)])]):[_c('a',{attrs:{"href":(_vm.explorerUrl + "address/" + value),"rel":"noopener","target":"_blank"}},[_c('f-ellipsis',{attrs:{"text":value || '',"overflow":"middle"}})],1)]]}},{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-7"},[(_vm.address)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.address,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_vm._v(" "+_vm._s(value)+" ")]],2)]):[(_vm.address)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.address,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_vm._v(" "+_vm._s(value)+" ")]]]}}],null,false,1268885738)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dAccountByAddressError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }