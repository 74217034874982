<template>
    <div class="account-header">
        <account-info-box />
    </div>
</template>

<script>
import AccountInfoBox from '../AccountInfoBox/AccountInfoBox.vue';
export default {
    components: { AccountInfoBox },
};
</script>

<style lang="scss">
@import 'style';
</style>
