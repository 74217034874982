var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"validator-list-dt",on:{"click":_vm.onClick}},[(!_vm.dValidatorListError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"mobile-view":_vm.cMobileView,"loading":_vm.cLoading,"first-m-v-column-width":"6","fixed-header":"","f-card-off":"","caption":"Validators"},scopedSlots:_vm._u([{key:"column-logo",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('div',{staticClass:"inline-img validator-img"},[(value)?_c('img',{staticClass:"not-fluid",attrs:{"src":value,"alt":item.stakerInfo.name}}):_c('img',{staticClass:"not-fluid",attrs:{"src":"img/skyhigh-logo.png","alt":"skyhigh logo"}})])])]):[_c('div',{staticClass:"inline-img validator-img"},[(value)?_c('img',{staticClass:"not-fluid",attrs:{"src":value,"alt":item.stakerInfo.name}}):_c('img',{staticClass:"not-fluid",attrs:{"src":"img/skyhigh-logo.png","alt":"skyhigh logo"}})])]]}},{key:"column-name",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('a',{staticClass:"break-word",attrs:{"href":(_vm.explorerUrl + "validator/" + (item.stakerAddress)),"target":"_blank","rel":"noopener"}},[_vm._v(" "+_vm._s(value)+" ")]),((item.stakerInfo ? item.stakerInfo.website || item.stakerInfo.contact : ''))?_c('a',{staticClass:"validator-website",attrs:{"href":(item.stakerInfo ? item.stakerInfo.website || item.stakerInfo.contact : ''),"target":"_blank","rel":"noopener"}},[_c('icon',{attrs:{"data":require("@/assets/svg/external-link-alt.svg")}})],1):_vm._e()])]):[_c('a',{staticClass:"break-word",attrs:{"href":(_vm.explorerUrl + "validator/" + (item.stakerAddress)),"rel":"noopener","target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]),((item.stakerInfo ? item.stakerInfo.website || item.stakerInfo.contact : ''))?_c('a',{staticClass:"validator-website",attrs:{"href":(item.stakerInfo ? item.stakerInfo.website || item.stakerInfo.contact : ''),"target":"_blank","rel":"noopener"}},[_c('icon',{attrs:{"data":require("@/assets/svg/external-link-alt.svg")}})],1):_vm._e()]]}},{key:"column-select",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col break-word align-center select-validator-mv"},[_c('button',{staticClass:"btn select-btn",attrs:{"disabled":item.isOffline || item.isCheater || item.alreadyDelegated,"data-validator-id":value}},[_vm._v(" Select ")])])]):[_c('button',{staticClass:"btn select-btn",attrs:{"disabled":item.isOffline || item.isCheater || item.alreadyDelegated,"data-validator-id":value}},[_vm._v(" Select ")])]]}}],null,false,1966609667)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dValidatorListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }