<template>
    <ul class="f-social-media-links no-markers">
        <li>
            <a href="https://discord.gg/" target="_blank" rel="noopener" title="SkyHigh on Discord">
                <icon data="@/assets/svg/social-media/discord.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://twitter.com/SkyHigh_Chain" target="_blank" rel="noopener" title="SkyHigh on Twitter">
                <icon data="@/assets/svg/social-media/twitter.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://t.me/SkyHighChat" target="_blank" rel="noopener" title="SkyHigh on Telegram">
                <icon data="@/assets/svg/social-media/telegram.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://github.com/skyhighblockchain/" target="_blank" rel="noopener" title="SkyHigh on Github">
                <icon data="@/assets/svg/social-media/github.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://medium.com/@skyhighchain" target="_blank" rel="noopener" title="SkyHigh on Medium">
                <icon data="@/assets/svg/social-media/medium.svg" width="24" height="24"></icon>
            </a>
        </li>
        <li>
            <a href="https://facebook.com/SkyHighChain" target="_blank" rel="noopener" title="SkyHigh on Facebook">
                <icon data="@/assets/svg/social-media/facebook.svg" width="24" height="24"></icon>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'SocialMediaLinks',
};
</script>

<style lang="scss">
@import 'style';
</style>
